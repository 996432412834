import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../sections/landing1/Hero";
import Feature from "../sections/smart-radio/Feature";
import Content1 from "../sections/smart-radio/Content1";
import Content2 from "../sections/smart-radio/Content2";
import Content3 from "../sections/smart-radio/Content3";
import Content4 from "../sections/smart-radio/Content4";
import Content5 from "../sections/smart-radio/Content5";
import Content6 from "../sections/smart-radio/Content6";
import CTA from "../sections/smart-radio/CTA";

import Testimonial from "../sections/landing1/Testimonial";
import NewsTestimonial from "../sections/landing1/NewsTestimonial";

// import Products from '../sections/landing1/Products'

import PageWrapper from "../components/PageWrapper";

const IndexPage = () => {
  return (
    <>
      <PageWrapper footerDark>
        <Helmet>
          <title>GoTalk - Hytera PoC Radios</title>
          <meta
            name="description"
            content="A leading supplier of ShopWatch &amp; TownLink radio systems, ShopSafe offer effective and affordable crime reduction radio schemes throughout the UK."
          />
        </Helmet>
        <Hero />
        <Feature />
        <Content6 bg="#FFF" />
        <Content3 />
        <Content2 />
        <Content4 bg="#FFF" />
        <Content1 />
        <Content5 />
        {/* <Products/> */}
        <Testimonial />
        <NewsTestimonial />
        <CTA />
      </PageWrapper>
    </>
  );
};
export default IndexPage;
