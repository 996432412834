import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { rgba } from "polished";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgContent from "../../assets/image/radios/dispatch-4.png";

interface ThemeProps {
  theme: DefaultTheme;
}

const ImgContainer = styled.div`
  margin-top: 50px;
  @media ${device.lg} {
    margin-top: 0px;
    position: absolute;
    top: 38%;
    left: 0;
    transform: translate(-53%, -45%);
  }
  @media ${device.xl} {
    transform: translate(-35%, -38%);
  }
  @media (min-width: 1400px) {
    transform: translate(-25%, -37%);
  }
`;

const Content1 = ({ bg = "#f7f7fb" }: { bg?: string }) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section className="position-relative" bg={bg}>
      <Container>
        <Row className="align-items-center justify-content-end">
          <Col lg="6" className=" position-static">
            <ImgContainer className=" pl-lg-5">
              <img
                src={imgContent}
                alt=""
                className="img-fluid"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                css={`
                  box-shadow: ${({ theme }) =>
                    `0 12px 84px ${theme.colors.shadow}`};
                  border-radius: 10px;
                `}
              />
            </ImgContainer>
          </Col>
          <Col xl="4" lg="5" className="mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>Alarm Handling</Title>
                <Text color="primary" fontSize={24} fontWeight="700">
                  Listen
                </Text>
                <Text variant="small">
                  When a user is in distress, an SOS call from any of our radios
                  streams audio to dispatch without interaction from the user.
                </Text>
                <Text mt={2} color="primary" fontSize={24} fontWeight="700">
                  See
                </Text>
                <Text variant="small">
                  All the radios send their location live to the dispatcher. The
                  VM780 goes one step further by streaming its camera footage
                  live too.
                </Text>
                <Text mt={2} color="primary" fontSize={24} fontWeight="700">
                  Respond
                </Text>
                <Text variant="small">
                  When the operator is ready to speak they can communicate in
                  full-duplex with the user.
                </Text>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content1;
