import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgContentBig from "../../assets/image/radios/vm-780.png";
// @ts-expect-error false positive image import failure
import imgOval from "../../assets/image/png/l2-content3-oval.png";
// @ts-expect-error false positive image import failure
import head1 from "../../assets/image/talking/head2-flipped.png";
// @ts-expect-error false positive image import failure
import head2 from "../../assets/image/talking/head3.png";

interface ThemeProps {
  theme: DefaultTheme;
}
const ImageRight = styled(Box)`
text-align: left;
  img {
    width: 80%;
  }
`;

const ShapeCard = styled(Box)`
width: 305px;
box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
position: absolute;
bottom: 20%;
left: 0%;
transform: scale(0.85);
@media ${device.sm} {
  left: 0;
  transform: scale(1);
}
@media ${device.md} {
  left: 0%;
}
`;

  const ShapeCard2 = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  bottom: 4%;
  right: -5%;
  transform: scale(0.85);
  @media ${device.sm} {
    right: -5%;
    transform: scale(1);
  }
  @media ${device.md} {
    right: -5%;
  }
`;


const HeadImage = styled.img`
  width: 55px;
  height: 55px;
`
const Content2 = ({ bg = "#f7f7fb" }: { bg?:string }) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section bg={bg}>
      <Container>
        <Row className="align-items-center">
          <Col lg="5" md="10" className="order-lg-1 mb-4">
            <div className="position-relative text-lg-right">
              <ImageRight
                className=""
                data-aos="fade-down"
                data-aos-duration="750"
                data-aos-once="true"
              >
                <img src={imgContentBig} alt="" className="img-fluid" />
              </ImageRight>

              {/* <ShapeCard
                bg="warning"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                >
                  <HeadImage margin-right={2} src={head1} alt="" />
                </Box>

                <Box ml={3}>
                  <Title textColor="light" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "A warning to all shopsafe user Joe Blogs has been spotted in town wearing a blue hoddie."
                  </Title>
                </Box>
              </ShapeCard>

              <ShapeCard2
                bg="secondary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center justify-content-end"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1500"
              >
                <Box pr="10px">
                  <Title textColor="light" textAlign="right" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "Thanks, I will keep an eye out for him."
                  </Title>
                </Box>
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  ml={3}
                >
                  <HeadImage margin-left={2} src={head2} alt="" />
                </Box>

              </ShapeCard2> */}
            </div>
          </Col>
          <Col lg="7" className="order-lg-2 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>Hytera VM780</Title>
                <Text mb={4}>
                Ideal for Local Authority, FM &amp; Security, The VM780 is our hybrid
                body camera and smart radio solution.
                Fully compliant of British Standards, record video, audio and capture
                photos for evidence.<br /><br />
                In an emergency press a single button to trigger local recording and
                stream your video footage live to you manager or control room.
                </Text>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
