import React from "react";
import styled, { DefaultTheme } from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

// @ts-expect-error false positive image import failure
import imgContentBig from "../../assets/image/radios/pnc-370.png";
// @ts-expect-error false positive image import failure
import head1 from "../../assets/image/talking/head1.png";
// @ts-expect-error false positive image import failure
import head2 from "../../assets/image/talking/head2.png";

interface ThemeProps {
  theme: DefaultTheme;
}

const ImageBottom = styled(Box)<ThemeProps>`
  border: 10px solid transparent;
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: 52%;
  left: -3%;
  z-index: 2;
  border-radius: 500px;
  overflow: hidden;
  max-width: 50%;

  @media ${device.md} {
    max-width: 100%;
  }
  @media ${device.lg} {
    left: 6%;
  }
  @media ${device.xl} {
    left: 8%;
  }
`;

const ShapeCard = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  bottom: 20%;
  left: 0%;
  transform: scale(0.85);
  @media ${device.sm} {
    left: 0;
    transform: scale(1);
  }
  @media ${device.md} {
    left: 0%;
  }
`;
const ShapeCard2 = styled(Box)`
  width: 305px;
  box-shadow: 0 32px 34px rgba(28, 27, 32, 0.2);
  position: absolute;
  bottom: 4%;
  right: -5%;
  transform: scale(0.85);
  @media ${device.sm} {
    right: -5%;
    transform: scale(1);
  }
  @media ${device.md} {
    right: -5%;
  }
`;
const HeadImage = styled.img`
  width: 55px;
  height: 55px;
`;
const Content2 = ({ bg = "#f7f7fb" }: { bg?: string }) => (
  <>
    {/* <!-- Content section 1 --> */}
    <Section bg={bg}>
      <Container>
        <Row className="align-items-center">
          <Col lg="5" md="10" className="order-lg-2 mb-4">
            <div className="position-relative text-right">
              <Box>
                <img src={imgContentBig} alt="" className="img-fluid" />
              </Box>

              {/* <ShapeCard
                bg="warning"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center"
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="500"
              >
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  pl={3}
                >
                  <HeadImage margin-right={2} src={head1} alt="" />
                </Box>

                <Box ml={3}>
                  <Title textColor="light" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "I've just had a man leave the store without paying, red top and blue jeans."
                  </Title>
                </Box>
              </ShapeCard>

              <ShapeCard2
                bg="secondary"
                p="18px"
                borderRadius={8}
                className="d-flex align-items-center justify-content-end"
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-once="true"
                data-aos-delay="1500"
              >
                <Box pr="10px">
                  <Title textColor="light" textAlign="right" fontSize={16} lineHeight="20px" variant="card" fontWeight={300} mb={0}>
                    "We've got him on camera and police are on their way."
                  </Title>
                </Box>
                <Box
                  width="30px"
                  minWidth="30px"
                  height="30px"
                  minHeight="30px"
                  bg="secondary"
                  color="light"
                  borderRadius="50%"
                  className="d-flex align-items-center justify-content-center"
                  mr={3}
                  ml={3}
                >
                  <HeadImage margin-left={2} src={head2} alt="" />
                </Box>

              </ShapeCard2> */}
            </div>
          </Col>
          <Col lg="7" className="order-lg-1 mt-5 mt-lg-0">
            <div
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-once="true"
            >
              <div>
                <Title>More than just a radio.</Title>
                <Text mb={4}>
                  The PNC370 is our base model, but that doesnt mean low
                  features. It boasts all the key features as standard for any
                  of our systems, including HD audio with dual micropones for
                  background audio suppression, SOS panic alarm and colour
                  screen as standard.
                  <br />
                  <br />
                  The PNC370 is ideal for businesses that want a cost
                  effetctive, easy-to-use, solution
                </Text>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default Content2;
